import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {authStore} from "@/store/user";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/PersonalInfoView.vue')
  },
  {
    path: '/questions',
    name: 'questions',
    component: () => import('../views/QuestionView.vue')
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('../views/PreviewView.vue')
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import('../views/ConfirmationView.vue')
  },
  // {
  //   path: '/presentation',
  //   name: 'presentation',
  //   component: () => import('../views/PresentationView.vue')
  // },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/ReportsView.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/reports/:id',
    name: 'report',
    component: () => import('../views/ReportView.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/admin_questions',
    name: 'admin_questions',
    component: () => import('../views/QuestionsView.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/admin_question/:id',
    name: 'admin_question',
    component: () => import('../views/AdminQuestionView.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/admin_users',
    name: 'admin_users',
    component: () => import('../views/UsersView.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/admin_users/:id',
    name: 'admin_user',
    component: () => import('../views/AdminUserView.vue'),
    meta: {
      requireLogin: true
    }
  },
    {
    path: '/reset-password/',
    name: 'forgot-pwd',
    component: () => import('../views/ForgotPwdView.vue'),
  },
  {
    path: '/password-reset/:uid/:token',
    name: 'pwd-reset',
    component: () => import('../views/ResetPwdView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0) // scroll to the top of the page
  const store = authStore()
  if (to.matched.some(record => record.meta.requireLogin) && !store.isAuthenticated) {
    next({ name: 'login', query: { to: to.path } });
  } else {
    next()
  }
})

export default router
