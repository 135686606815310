<template>
  <Disclosure as="nav" class="bg-primary z-10" v-slot="{ open }">
    <div class="mx-auto container flex items-center w-full h-full">
      <div class="relative flex items-center justify-between w-full">
        <div class="absolute inset-y-0 left-0 flex items-center w-full sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>

        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center bg-white p-1 rounded">
            <img class="block h-10 w-auto lg:hidden" src="@/assets/logo_litiss.jpg" alt="Litiss" />
            <img class="hidden h-8 w-auto lg:block" src="@/assets/logo_simple.jpg" alt="Your Company" />
          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex items-center h-full space-x-4">
              <router-link v-for="item in navigation" :key="item.name" :to="{name: item.href}" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-opacity-50 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium capitalize']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</router-link>
            </div>
          </div>
        </div>

        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

          <!-- Profile dropdown -->
          <Menu v-if="isAuthenticated" as="div" class="relative ml-3">
            <div>
              <MenuButton class="flex rounded-full bg-secondary text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" src="../assets/user.png" alt="" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute flex flex-col right-0 top-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-slot="{ active }">
                  <button @click="profile" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full']">Mon Profil</button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button @click="reports" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full']">Rapports</button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button @click="questions" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full']">Questions</button>
                </MenuItem>
                <MenuItem v-if="isStaff"  v-slot="{ active }">
                  <button @click="users" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full']">Utilisateurs</button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button @click="logout" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full']">Me Déconnecter</button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2 bg-primary mt-6 rounded-md">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="button" @click="goto(item.href)" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium capitalize w-full']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import {authStore} from "@/store/user";
import {computed} from "vue";
import router from "@/router";

const store = authStore()
const isAuthenticated = computed(() => store.isAuthenticated)
const isStaff = computed(() => store.user.is_staff)

const logout = async () => {
  await store.logout()
  await router.push({name: 'home'})
}

const profile = async () => {
  await router.push({name: 'profile'})
}

const reports = async () => {
  await router.push({name: 'reports'})
}

const questions = async () => {
  await router.push({name: 'admin_questions'})
}

const users = async () => {
  await router.push({name: 'admin_users'})
}

const goto = async (to) => {
  await router.push({name: to})
}

const navigation = [
  { name: 'Home', href: 'home' },
  { name: 'La méthode Litiss', href: 'about' },
  // { name: 'Notre projet', href: 'presentation' },
  // { name: 'Reports', href: 'reports' },
]
</script>
